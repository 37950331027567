<template>
  <div>
    <div
      class="hero-image mt-3"
      :style="{ backgroundImage: 'url(' + bgImages[0] + ')' }"
    >
      <div class="hero-text">
        <h1>Join us at Amchara Gozo</h1>
      </div>
    </div>

    <featured-in></featured-in>

    <div class="body-content mx-auto">
      <!-- A detox retreat Section -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 mx-auto text-center">
            <div class="green-header-2-text">
              A detox retreat in year-long sunshine
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-9 mx-auto pb-lg-30 text-center">
            <p class>
              Our Malta detox health retreat is a personalised health experience
              in the sun. It is for anyone who wishes to improve their physical
              health, emotional wellbeing and lifestyle in a positive,
              sustainable way.
            </p>
            <p class>
              Located on the picturesque island of Gozo in Malta, our stunning
              retreat is the perfect setting for you to relax, reboot and
              recharge. Boasting year-long sunshine, you can enjoy a large
              swimming pool and spa facilities, comfortable apartments and a
              yoga studio offering glorious sunset views.
            </p>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-10 mx-auto text-center">
            <router-link
              to="/enquiries"
              class="btn d-inline-block btn-orange-gradient enquire-now-button"
              >ENQUIRE NOW</router-link
            >
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />

      <!-- Amchara Malta Slider Section -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-8 mx-auto text-center">
            <div class="green-header-2-text">
              Take a look around Amchara Malta
            </div>

            <!-- Image Slider Section -->
            <ul id="lightSlider_home_malta">
              <li
                v-for="file in maltaImages"
                :data-thumb="'/images/location-slider/malta/' + file"
                :data-src="'/images/location-slider/malta/' + file"
              >
                <img
                  width="100%"
                  :src="'/images/location-slider/malta/' + file"
                  alt="Amchara Health Retreats Gozo, Malta"
                />
              </li>
            </ul>

            <div class="mb-3">&nbsp;</div>
            <router-link
              to="/malta-gallery"
              class="btn btn-green-gradient green-link-button"
              >Full Gallery</router-link
            >
          </div>
        </div>
      </div>

      <div class="mt-2 mb-2">&nbsp;</div>

      <trip-advisor></trip-advisor>

      <hr width="80%" class="mt-5" />

      <!-- A detox retreat Section -->
      <div class="container-fluid mb-2">
        <div class="row">
          <div class="col-md-12 mx-auto pb-lg-30">
            <div class="green-header-2-text text-center">
              A personalised health retreat tailored to your needs
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9 mx-auto text-center orange-text">
            <p>
              <i
                >Your Amchara retreat experience will be designed with your
                personal health needs and goals in mind. You will be immersed in
                a supportive and nurturing environment that enables you to
                switch off, relax and kickstart your health journey.</i
              >
            </p>
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3">&nbsp;</div>

      <featured-mags></featured-mags>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />

      <!-- Whats included Section -->
      <div class="container-fluid mb-5">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="green-header-2-text text-center">
              What’s included in a typical retreat
            </div>
            <div class="row">
              <div class="col-md-7 mb-5 mb-md-0">
                <div class="row">
                  <div class="col-md-12">
                    <ul class="home-bullet-points-left">
                      <li class="p-list-item mb-2 mb-md-0">
                        Advanced doctors screening
                      </li>
                      <li class="p-list-item mb-2 mb-md-0">
                        1:1 health screening on arrival
                      </li>
                      <li class="p-list-item mb-2 mb-md-0">
                        Personalised health experience
                      </li>
                      <li class="p-list-item mb-2 mb-md-0">
                        Luxury spa facilities
                      </li>
                      <li class="p-list-item mb-2 mb-md-0">
                        4 x juices, smoothies, soups or broths
                      </li>
                      <li class="p-list-item mb-2 mb-md-0">
                        Detox supplements
                      </li>
                      <li class="p-list-item mb-2 mb-md-0">
                        Daily educational talks
                      </li>
                      <li class="p-list-item mb-2 mb-md-0">
                        Hosted sightseeing walks
                      </li>
                      <li class="p-list-item mb-2 mb-md-0">
                        Yoga, medication and aqua aerobics
                      </li>
                      <li class="p-list-item mb-2 mb-md-0">
                        Tailored nutritional advice &amp; emotional support
                      </li>
                      <li class="p-list-item mb-2 mb-md-0">
                        Exit health screening
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-5 my-auto">
                <img
                  class="mx-auto d-block img-fluid"
                  src="@/assets/images/pages/malta/whats-included-image.jpg"
                  alt="Whats included image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container mb-2">
        <div class="row">
          <div class="col-md-10 mx-auto text-center">
            <router-link
              to="/enquiries"
              class="btn d-inline-block btn-orange-gradient enquire-now-button"
              >ENQUIRE NOW</router-link
            >
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />

      <!-- Optional extras Section -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="green-header-2-text text-center">
              Optional extras to enhance your stay
            </div>
            <div class="row">
              <div class="col-md-5 mb-4 mb-md-0">
                <img
                  class="mx-auto d-block img-fluid"
                  src="@/assets/images/pages/malta/extras-image.jpg"
                  alt="Extras image"
                />
              </div>
              <div class="col-md-7 mx-auto mb-5">
                <div class="row">
                  <div class="col-md-12">
                    <ul class="home-bullet-points-left">
                      <li class="p-list-item mb-2 mb-md-3">
                        Health and spa treatments - massage, reiki
                      </li>
                      <li class="p-list-item mb-2 mb-md-3">
                        Emotional support - 1:1 personal sessions
                      </li>
                      <li class="p-list-item mb-2 mb-md-3">
                        Colon cleansing – colonic hydrotherapy or/and enemas
                      </li>
                      <li class="p-list-item mb-2 mb-md-3">
                        Prescribed super supplement programme from a range of 6
                        super supplements including; wheatgrass, blue/green
                        algae, Aloe Ferox
                      </li>
                      <li class="p-list-item mb-2 mb-md-3">
                        Personalised health tests for hormones, genetics and gut
                        health
                      </li>
                      <li class="p-list-item mb-2 mb-md-3">
                        Tailored food programme derived from raw, vegan,
                        Palaeolithic and vegetarian Palaeolithic options all
                        using organic ingredients (whenever possible)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr width="80%" />
      <team></team>

      <hr width="80%" />

      <!-- Vimeo videos Section -->
      <div class="container-fluid mb-2">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="green-header-2-text text-center">
              Hear from our clients
            </div>
          </div>
        </div>
        <video-testimonials></video-testimonials>
      </div>

      <div class="mt-2 mb-2">&nbsp;</div>
      <hr width="80%" />

      <!-- Rooms Section -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 mx-auto pb-lg-30">
            <div class="green-header-2-text text-center">
              Apartments in Gozo
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="row">
              <div class="col-md-6 text-center">
                <div class="orange-text">Executive Apartments</div>
                <img
                  class="mx-auto d-block mb-4 img-fluid"
                  src="@/assets/images/pages/malta/executive-rooms.jpg"
                  alt="Executive rooms image"
                />
                <p>
                  Executive apartments are brighter and offer more hours of
                  sunlight throughout the day. They also provide the best views
                  of the swimming pool and are conveniently situated for access
                  to the facilities including the spa garden, juice room and the
                  clinic.
                </p>
                <p style="color: #83c550">
                  Large Flat Screen TV | En-suite Bathroom | Hairdryer
                  <br />Small Kitchenette Area | Fresh Towels Daily
                  <br />Comfortable Beds | Free Wi-fi | Organic Soaps
                  <br />Lounge Area
                </p>
              </div>
              <div class="col-md-6 text-center">
                <div class="orange-text">Standard Apartments</div>
                <img
                  class="mx-auto d-block mb-4 img-fluid"
                  src="@/assets/images/pages/malta/standard-rooms.jpg"
                  alt="Standard rooms image"
                />
                <p>
                  Our standard apartments are decorated to a high standard and
                  feature a solid oak double bed with a comfortable handmade
                  mattress, spacious wardrobe with plenty of hangers and a large
                  freestanding mirror.
                  <br />
                  <br />
                </p>
                <p style="color: #83c550">
                  Flat Screen TV | En-suite Bathroom | Hairdryer
                  <br />Small Kitchenette Area | Fresh Towels Daily
                  <br />Comfortable Beds | Free Wi-fi | Organic Soaps
                  <br />Lounge Area
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container mb-2">
        <div class="row">
          <div class="col-md-10 mx-auto text-center">
            <router-link
              to="/enquiries"
              class="btn d-inline-block btn-orange-gradient enquire-now-button"
              >ENQUIRE NOW</router-link
            >
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />

      <!-- Whats included Section -->
      <div class="container-fluid mb-5">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="green-header-2-text text-center">
              The benefits of a stay with us...
            </div>
            <div class="row">
              <div class="col-md-7 my-auto">
                <div class="row">
                  <div class="col-md-12">
                    <ul class="home-bullet-points-left">
                      <li class="p-list-item mb-2 mb-md-4">
                        Personalised plan tailored to you
                      </li>
                      <li class="p-list-item mb-2 mb-md-4">
                        The chance to relax, recharge & reboot
                      </li>
                      <li class="p-list-item mb-2 mb-md-4">
                        Find your route back to optimum health
                      </li>
                      <li class="p-list-item mb-2 mb-md-4">
                        Emotional support throughout your stay
                      </li>
                      <li class="p-list-item mb-2 mb-md-4">
                        Before & after screening to see how far you’ve come
                      </li>
                      <li class="p-list-item mb-2 mb-md-4">
                        The tools needed to keep on track at home
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-5 text-center my-auto">
                <img
                  width="90%"
                  class="mt-5 mt-md-0"
                  src="@/assets/images/pages/malta/benefits-image.jpg"
                  alt="Benefits image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Malta prices section -->
      <!-- <div class="container-fluid mb-2">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="green-header-2-text text-center">Gozo Prices</div>
            <div class="row">
              <div class="col-md-6">
                <div class="orange-text text-center mb-3">Standard Weeks*</div>
                <div class="table-responsive">
                  <table class="table info-table info-table-big">
                    <thead>
                      <tr>
                        <td>
                          Number
                          <br />of Days
                        </td>
                        <td>
                          <font class="font-weight-bold">Standard</font>
                          Apartment
                          <br />
                          <font class="orange-text small"
                            >Private&nbsp;&nbsp;&nbsp;Shared (p.p.)</font
                          >
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>5</td>
                        <td>
                          <div class="d-inline me-4">£1250</div>
                          <div class="d-inline ms-4">£900</div>
                        </td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>
                          <div class="d-inline me-4">£1350</div>
                          <div class="d-inline ms-4">£950</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          8+
                          <br />
                          <span style="font-size: 13px">
                            <i>(price&nbsp;per&nbsp;day)</i>
                          </span>
                        </td>
                        <td>
                          <div class="d-inline ms-2 me-4">£190</div>
                          <div class="d-inline ms-4">£145</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-6">
                <div class="orange-text text-center mb-3">Premium Weeks**</div>
                <div class="table-responsive">
                  <table class="table info-table info-table-big">
                    <thead>
                      <tr>
                        <td>
                          Number
                          <br />of Days
                        </td>
                        <td>
                          <font class="font-weight-bold">Standard</font>
                          Apartment
                          <br />
                          <font class="orange-text small"
                            >Private&nbsp;&nbsp;&nbsp;Shared (p.p.)</font
                          >
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>5</td>
                        <td>
                          <div class="d-inline me-4">£1350</div>
                          <div class="d-inline ms-4">£1000</div>
                        </td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>
                          <div class="d-inline me-4">£1600</div>
                          <div class="d-inline ms-4">£1150</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          8+
                          <br />
                          <span style="font-size: 13px">
                            <i>(price&nbsp;per&nbsp;day)</i>
                          </span>
                        </td>
                        <td>
                          <div class="d-inline me-4">£220</div>
                          <div class="d-inline ms-4">£165</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="container mt-5">
        <div class="row">
          <div class="col-md-6 mx-auto">
            <div class="row">
              <div class="col-md-6 mx-auto text-center mb-4 mb-md-0">
                <router-link
                  to="/malta-pricing"
                  class="btn btn-green-gradient green-link-button"
                  >View Full Pricing</router-link
                >
              </div>
              <div class="col-md-6 col-sm-12 mx-auto text-center">
                <router-link
                  to="/enquiries"
                  class="
                    btn
                    d-inline-block
                    btn-orange-gradient
                    enquire-now-button
                  "
                  >ENQUIRE NOW</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />

      <testimonials></testimonials>

      <div class="mt-3 mb-3">&nbsp;</div>
      <!--<hr width="80%" />
      <instagram></instagram>-->
    </div>
    <!-- body-content End -->

    <newsletter-cta></newsletter-cta>
  </div>
</template>

<script>
import FeaturedIn from "./page-partials/FeaturedIn";
import Team from "./page-partials/Team";
import FeaturedMags from "./page-partials/FeaturedMags";
import Locations from "./page-partials/Locations";
import TripAdvisor from "./page-partials/TripAdvisor";
import Testimonials from "./page-partials/Testimonials";
import Instagram from "./page-partials/Instagram";
import NewsletterCta from "./page-partials/NewsletterCta";
import VideoTestimonials from "./page-partials/VideoTestimonials";

export default {
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find(
        i => i.slug == this.$route.path.replace("/", "")
      ).title,
      content: this.$store.seo_metas.find(
        i => i.slug == this.$route.path.replace("/", "")
      ).description
      // titleTemplate: null,
    };
  },
  data() {
    return {
      maltaImages: [],
      bgImages: [require("@/assets/images/pages/malta/header.jpg")]
    };
  },
  methods: {
    fetchSliderImages() {
      window.axios.get("/location-slider-fetch-images").then(({ data }) => {
        this.maltaImages = data.malta;
        this.redesignSlider("lightSlider_home_malta");
      });
    },
    redesignSlider(id) {
      var slider = $("#" + id).lightSlider({
        item: 1,
        autoWidth: false,
        slideMove: 1, // slidemove will be 1 if loop is true
        slideMargin: 4,

        addClass: "",
        mode: "slide",
        useCSS: true,
        cssEasing: "ease", //'cubic-bezier(0.25, 0, 0.25, 1)',//
        easing: "linear", //'for jquery animation',////

        speed: 800, //ms'
        auto: false,
        loop: false,
        pause: 4000,

        keyPress: false,
        controls: true,
        prevHtml:
          '<i class="fas fa-chevron-circle-left fa-2x" style="color: #8bc34a;"></i>',
        nextHtml:
          '<i class="fas fa-chevron-circle-right fa-2x" style="color: #8bc34a;"></i>',

        rtl: false,
        adaptiveHeight: false,

        vertical: false,
        verticalHeight: 380,
        vThumbWidth: 100,

        thumbItem: 6,
        pager: false,
        gallery: false,
        galleryMargin: 5,
        thumbMargin: 5,
        currentPagerPosition: "middle",

        enableTouch: true,
        enableDrag: true,
        freeMove: false,
        swipeThreshold: 40,

        responsive: [
          {
            breakpoint: 800,
            settings: {
              thumbItem: 5,
              controls: true,
              gallery: false
            }
          }
        ],

        onBeforeStart: function(el) {},
        onSliderLoad: function(el) {},
        onBeforeSlide: function(el) {},
        onAfterSlide: function(el) {},
        onBeforeNextSlide: function(el) {},
        onBeforePrevSlide: function(el) {}
      });

      slider.refresh();
      //slider.play();
    }
  },
  mounted() {
    this.fetchSliderImages();
  },
  components: {
    FeaturedIn,
    Team,
    FeaturedMags,
    Locations,
    TripAdvisor,
    Testimonials,
    Instagram,
    NewsletterCta,
    VideoTestimonials
  }
};
</script>

<style>
</style>